import { Modal, useMantineTheme, Text, createStyles } from '@mantine/core';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { Auth } from '@supabase/auth-ui-react';
import { useState } from 'react';
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared';
import { useMediaQuery, useScrollLock } from '@mantine/hooks';

const useStyles = createStyles({
  modalInner: {
    paddingTop: 100,
  },
});
export default function LoginModal({
  opened,
  onClose,
  isSignUp,
}: {
  opened: boolean;
  isSignUp: boolean;
  onClose: () => void;
}) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [supabase] = useState(() => createBrowserSupabaseClient());
  const largeScreen = useMediaQuery('(min-width: 60em)');
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        classNames={{ inner: classes.modalInner }}
        overlayProps={{
          opacity: 0.9,
        }}
        centered
        title={
          <Text fw={900} color="violet">
            {isSignUp
              ? 'Sign up for free to start creating tier lists'
              : 'Sign in to start creating tier lists!'}
          </Text>
        }
        fullScreen={!largeScreen}
      >
        <Auth
          redirectTo={typeof window !== 'undefined' ? window.location.href : undefined}
          supabaseClient={supabase}
          providers={['google', 'facebook']}
          theme={theme.colorScheme}
          socialLayout="horizontal"
          view={isSignUp ? 'sign_up' : undefined}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: theme.colors.violet[6],
                  brandAccent: theme.colors.violet[6],
                },
                fonts: {
                  bodyFontFamily: 'var(----default-font-family)',
                  buttonFontFamily: 'var(----default-font-family)',
                  inputFontFamily: 'var(----default-font-family)',
                  labelFontFamily: 'var(----default-font-family)',
                },
                fontSizes: {
                  baseInputSize: '16px',
                },
              },
            },
          }}
        />
      </Modal>
    </>
  );
}
